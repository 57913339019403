import React, { useEffect, useRef, useState } from 'react';
import '@oev-dxp/oev-components/dist/datepicker';
import { DatePickerProps } from './Interfaces';
import { DatePickerEvent } from './Constants';
import { setLabelText } from '../../utils/setLabelText';

// TODO Change validation to use validity state
function DatePicker({
	default: defaultValue,
	disabled: isDisabled,
	earliest,
	inlineLabel,
	label,
	latest,
	mandatory,
	name,
	onChange,
	outOfRangeErrorMessage,
	placeholder,
	tooltip,
	...props
}: Readonly<DatePickerProps>) {
	const ref = useRef<HTMLInputElement | null>();
	const isMandatory = mandatory?.choice === 'mandatory';
	const errorText = mandatory?.errorMessage ?? '';
	const [validity, setValidity] = useState<boolean>(false);

	useEffect(() => {
		const handleChange = (ev: CustomEvent) => {
			onChange({ value: ev.detail.value });

			if (!ev.detail.validity) return;
			setValidity(ev.detail.validity.valid);
		};

		ref.current?.addEventListener(DatePickerEvent.changeEvent, handleChange);
		ref.current?.addEventListener(DatePickerEvent.inputEvent, handleChange);

		return () => {
			ref.current?.removeEventListener(DatePickerEvent.changeEvent, handleChange);
			ref.current?.removeEventListener(DatePickerEvent.inputEvent, handleChange);
		};
	}, []);

	return (
		<oev-datepicker
			{...props}
			custom-change-event-name={DatePickerEvent.changeEvent}
			default={defaultValue}
			earliest={earliest}
			errortext={errorText}
			id={name}
			inlinelabel={inlineLabel}
			label={setLabelText(label, isMandatory)}
			latest={latest}
			name={name}
			placeholder={placeholder}
			ref={ref}
			{...(validity ? null : { regex: '/[]/' })} // regex is set for validateForms to acknowledge error
			regexerrortext={outOfRangeErrorMessage}
			tooltip={tooltip}
			{...(isDisabled ? { disabled: '' } : null)}
			{...(isMandatory ? { mandatory: '' } : null)}
		/>
	);
}

export default DatePicker;
