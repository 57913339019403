export enum IbanEvent {
	change = '@Iban/change',
	invalidate = '@Iban/invalidate',
	validate = '@Iban/validate',
}

export const IbanErrorMessage = {
	fetch: 'IBAN Validierung fehlgeschlagen',
};

export const componentAttributeMapping: { [attribute: string]: string; } = {
	errorMessageIbanValidation: 'errormessage-iban-validation',
	inlineLabelIban: 'inline-label',
	labelBank: 'label-bank',
	labelIban: 'label-iban',
	placeholderBank: 'placeholder-bank',
	placeholderIban: 'placeholder-iban',
	regexIban: 'regex-iban',
	required: 'required',
	requiredErrorMessage: 'required-error-message',
	tooltip: 'tooltip',
	validate: 'validate',
	valueFinancialInstitution: 'value-financial-institution',
	valueIban: 'value-iban',
};
