import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { ErrorView } from '../../view/ErrorView';
import Section from '../../components/Section';
import Summary from '../../components/Summary';
import Navigation from '../../components/Navigation';
import ProgressTabs from '../../components/ProgressTabs';
import ActiveScreen from '../../stores/selector/ActiveScreenSelector';
import FormConfig from '../../stores/atom/FormConfig';
import InitialScreenRender from '../../stores/atom/isInitialScreenRender';
import ScreenProgress from '../../stores/atom/ScreenProgress';
import { scrollToElementWithOffset } from '../../utils';
import { ScreenElementsProps } from './interfaces';

// ToDo: Refactor with switch statement
function ScreenElements({ section, summary, navigation }: Readonly<ScreenElementsProps>) {
	if (summary) {
		return (
			<Summary key={summary.uuid} {...summary} />
		);
	}
	if (navigation) {
		const flattenButtons = navigation.buttons.map((button) => {
			if (button.actionButton === null) {
				return button.navigationButton;
			}
			return button.actionButton;
		});
		return (
			<oev-container-v1>
				<oev-row-v1>
					<Navigation
						condition={navigation.condition}
						flattenButtons={flattenButtons}
						key={navigation.uuid}
						uuid={navigation.uuid}
					/>
				</oev-row-v1>
			</oev-container-v1>
		);
	}
	if (section) {
		return (
			<Section
				key={section?.uuid}
				{...section}
			/>
		);
	}
	return <ErrorView text="Error inside form" />;
}

export function ProgressTabsElement() {
	const config = useRecoilValue(FormConfig);
	if (!config?.progressTabs || !config?.screens) return null;

	const screens = config?.screens;
	const headlines = screens.map((screen) => screen.headline).slice(0, screens.length - 1).join();
	const activeScreen = useRecoilValue(ScreenProgress);
	if ((screens.length - 1) === activeScreen) return null;
	return (
		<oev-container-v1>
			<oev-row-v1>
				<oev-column-v1>
					<ProgressTabs activeKey={activeScreen} keys={headlines} />
				</oev-column-v1>
			</oev-row-v1>
		</oev-container-v1>
	);
}

function ScreenFactory() {
	const screen = useRecoilValue(ActiveScreen);

	const isInitialScreenRender = useRecoilValue(InitialScreenRender);

	useEffect(() => {
		if (!isInitialScreenRender) return;
		scrollToElementWithOffset('top-scroll-point', 20);
	}, [screen, isInitialScreenRender]);
	// TODO Key after Navigation is complete
	return (
		<div data-testid="testIdScreen" id="top-scroll-point" key={screen?.id}>
			<ProgressTabsElement />
			{screen?.elements.map((element) => (
				<ScreenElements
					key={element.navigation?.uuid || element.summary?.uuid || element.section?.uuid}
					{...element}
				/>
			))}
		</div>
	);
}

export default ScreenFactory;
