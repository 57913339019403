import ibanValidation from '../../../apiClient/serviceLayer/ibanValidation';
import { IbanErrorMessage } from '../components/Constants';

export async function getIbanValidation(
	iban: string,
	errorHandlerCallback: () => void,
) {
	try {
		const res = await ibanValidation(iban);
		return res;
	} catch (err: unknown) {
		if (err instanceof Response && err?.status === 400) {
			errorHandlerCallback();
			return;
		}
		// eslint-disable-next-line no-alert
		alert(IbanErrorMessage.fetch);
		throw new Error('Error while fetching IBAN validation', { cause: err });
	}
}
